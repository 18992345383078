<template>
  <b-card>
    <div>
      <validation-observer mode="eager" ref="validator">
        <b-form>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Name" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="form.name" :state="errors.length ? false : null" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Email" label-for="email">
                <validation-provider #default="{ errors }" name="email" rules="required|email">
                  <b-form-input id="email" v-model="form.email" type="email" :state="errors.length ? false : null" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Password" label-for="password">
                <validation-provider #default="{ errors }" name="password" rules="required|password">
                  <b-form-input id="password" v-model="form.password" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Role -->
            <b-col cols="12" md="4">
              <b-form-group label="Admin Role" label-for="admin-role">
                <validation-provider #default="{ errors }" name="admin-role" rules="required">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.id"
                    input-id="admin-role"
                    v-model="form.roleId"
                    label="name"
                    :options="roles"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action Buttons -->
          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
            Create admin
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required, password } from '@validations'

export default {
  name: 'CreateAdminCreate',
  components: {
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      adminId: null,
      form: {
        name: '',
        email: '',
        password: '',
        roleId: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      roles: 'roles/roles',
    }),
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchRoles: 'roles/fetchRoles',
      createAdminUser: 'adminUser/createAdminUser',
    }),

    initState() {
      this.registerValidator(this.$refs.validator)
      this.fetchRoles()
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      this.create()
    },

    create() {
      this.waitRequest(() => {
        return this.createAdminUser(this.form)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Admin is added',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.push('/create-admin')
          })
          .catch(this.checkErrors)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
